import React from "react";
import { navigate, PageProps } from "gatsby";
import { Layout } from "../layouts/layout";
import { SEO } from "../seo";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Products } from "../organisms/products";
import { GatsbyShop } from "../utils/shop";

export default ({
  pageContext: { shop, order, root },
}: PageProps<{}, { shop: GatsbyShop; order: boolean; root: string }>) => {
  const key =
    shop.stripe.env === "live"
      ? process.env.STRIPE_PUBLIC_KEY_LIVE
      : process.env.STRIPE_PUBLIC_KEY_TEST;
  const stripe = loadStripe(String(key));
  return (
    <Layout shop={shop} root={root}>
      <SEO
        title={`${order ? "Commande" : "Boutique"} | ${shop.name}`}
        description={`Achetez nos vins en ligne (${[
          ...new Set(
            shop.products.filter(({ show }) => show).map(({ title }) => title)
          ),
        ].join(", ")}). Livraison à domicile directement au départ du domaine.`}
        noindex={order}
      />
      <Elements stripe={stripe}>
        <Products
          shop={shop}
          root={root}
          order={order}
          onBack={() => navigate(`${root}/`)}
          onCheckout={() => navigate(`${root}/commande`)}
        />
      </Elements>
    </Layout>
  );
};
